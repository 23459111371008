<template>
  <div>
    <div class="flex items-center gap-2">
      <input
        :id="id"
        type="checkbox"
        :checked="checked"
        v-bind="attrs"
        class="border-[1.5] h-4 w-4 rounded checked:bg-indigo-500 checked:hover:bg-indigo-500 checked:focus:bg-indigo-500 focus:outline-none focus:border-blue-600 focus:ring-4 focus:ring-blue-300"
        :class="{
          'border-red-300': errors.length,
          'border-slate-300': !errors.length,
        }"
      >

      <label
        v-if="label || slots.default"
        :for="id"
        class="font-inter text-sm text-slate-600"
      >
        <span v-if="label">{{ label }}</span>
        <slot v-else />
      </label>
    </div>

    <div
      id="checkbox-component-errors-container"
      v-for="(error, index) in errors"
      :key="index"
      class="mt-2"
    >
      <div class="text-red-500 text-sm">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  const slots = useSlots()

  defineOptions({
    inheritAttrs: false,
  })

  const attrs = useAttrs();

  defineProps({
    id: {
      type: String,
      default: '',
    },

    checked: {
      type: Boolean,
    },

    label: {
      type: String,
    },

    errors: {
      type: Array,
      default: [],
    },
  })

  const emit = defineEmits();

  function updateValue(event: any) {
    emit('update:modelValue', event.target.checked);
  }
</script>
